<template>
  <div class="create-company">
    <div v-if="isEmailVerified">
      <ContentHeader :title="$gettext('Create a new company')">
        <template #description>
          {{
            $gettext(
              "Enter your company information, such as company's name, address and contact information"
            )
          }}
        </template>
      </ContentHeader>
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="handleSubmit">
            <!-- Start Basic Information-->
            <div class="row">
              <FormInput
                class="col-md-6"
                :label="$gettext('Company name')"
                field="name_fr"
                v-model="payload.name_fr"
                required
              />
              <FormInput
                class="col-md-6"
                :label="$gettext('Website')"
                field="website"
                v-model="payload.website"
              />

              <FormInput
                class="col-md-6"
                :label="$gettext('Email address')"
                field="email"
                v-model="payload.email"
              />
              <FormInput
                class="col-md-3"
                :label="$gettext('Phone number')"
                field="phone_number"
                v-model="payload.phone_number"
              />
              <FormInput
                class="col-md-3"
                :label="$gettext('Number of employees')"
                field="employee_count"
                v-model="payload.employee_count"
              />
              <Editor
                class="col-md-12"
                v-model="payload.description_fr"
                :label="$gettext('Description')"
                field="description_fr"
                required
              />
              <!-- End Basic Information-->

              <div class="col-md-12">
                <h4><translate>Logo and banner</translate></h4>
              </div>
              <FormGroupFile
                class="col-md-6"
                :label="$gettext('Logo')"
                field="logo"
                v-model="payload.logo"
                required
                accept="image/jpeg, image/png"
              />
              <FormGroupFile
                class="col-md-6"
                :label="$gettext('Banner')"
                field="banner"
                v-model="payload.banner"
                accept="image/jpeg, image/png"
              />

              <div class="col-md-12">
                <h4><translate>Location</translate></h4>
              </div>
              <FormGroupGmapAutocomplete
                class="col-md-12"
                :label="$gettext('Address')"
                field="address"
                v-model="payload.address"
                required
                :placeholder="$gettext('Enter location')"
              />
              <div class="col-md-12">
                <h4><translate>Social media</translate></h4>
              </div>
              <FormInput
                class="col-md-6"
                :label="$gettext('Linkedin')"
                field="linkedin_url"
                type="url"
                v-model="payload.linkedin_url"
              />
              <FormInput
                class="col-md-6"
                :label="$gettext('Facebook')"
                field="facebook_url"
                type="url"
                v-model="payload.facebook_url"
              />

              <FormInput
                class="col-md-6"
                :label="$gettext('Twitter')"
                field="twitter_url"
                type="url"
                v-model="payload.twitter_url"
              />
              <FormInput
                class="col-md-6"
                :label="$gettext('Instagram')"
                field="instagram_url"
                type="url"
                v-model="payload.instagram_url"
              />
            </div>

            <router-link :to="{ name: 'companies' }" class="btn btn-danger"
              ><translate>Cancel</translate></router-link
            >
            <SubmitButton :label="$gettext('Save')" class="btn btn-info" />
          </form>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="d-flex justify-content-center w-100 p-lg-5">
        <div class="text-center m-lg-5">
          <div>
            <i class="material-icons display-4">
              email
            </i>
          </div>
          <div v-show="resendEmailConfirmationEmailSuccess">
            <div class="my-3">
              <translate
                >We have sent you another verification email.</translate
              >
            </div>
          </div>
          <div v-show="!resendEmailConfirmationEmailSuccess">
            <div class="my-3">
              <translate>Please verify your email</translate>
            </div>
            <b-button
              class="btn btn-primary"
              :disabled="resendEmailConfirmationEmailPosting"
              @click="handleResendEmailConfirmationEmail"
            >
              <translate>Resend</translate>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from "@/components/FormGroups/Editor";
import { mapActions, mapGetters } from "vuex";
import FormInput from "@/components/FormGroups/FormInput";
import FormGroupGmapAutocomplete from "@/components/FormGroups/FormGroupGmapAutocomplete";
import FormGroupFile from "@/components/FormGroups/FormGroupFile";
import SubmitButton from "@/components/SubmitButton";
import ContentHeader from "@/components/ContentHeader";
import { scrollToFirstElement } from "@/utils/scrollTo";

export default {
  components: {
    SubmitButton,
    FormGroupFile,
    FormInput,
    Editor,
    FormGroupGmapAutocomplete,
    ContentHeader
  },
  data: () => ({
    test: "",
    payload: {
      name_fr: "",
      description_fr: "",
      website: "",
      email: "",
      phone_number: "",
      employee_count: "",
      address: "",
      logo: [],
      banner: [],
      linkedin_url: "",
      facebook_url: "",
      twitter_url: "",
      instagram_url: ""
    },
    resendEmailConfirmationEmailPosting: false,
    resendEmailConfirmationEmailSuccess: false
  }),
  computed: {
    ...mapGetters("me", ["isEmailVerified"])
  },
  methods: {
    ...mapActions("companies", ["create"]),
    ...mapActions("form", ["submit"]),
    ...mapActions("me", ["resendEmailConfirmationEmail"]),
    handleResendEmailConfirmationEmail() {
      this.resendEmailConfirmationEmailPosting = true;
      this.resendEmailConfirmationEmail()
        .then(() => (this.resendEmailConfirmationEmailSuccess = true))
        .finally(() => (this.resendEmailConfirmationEmailPosting = false));
    },
    handleSubmit() {
      const form = Object.keys(this.payload).reduce((form, index) => {
        form.append(index, this.payload[index]);
        return form;
      }, new FormData());
      form.append("logo", this.payload.logo);
      form.append("banner", this.payload.banner);

      this.submit(() => this.create(form))
        .then(() => {
          this.$router.push({ name: "companies" });
        })
        .catch(() => scrollToFirstElement(".create-company form .is-invalid"));
    }
  }
};
</script>
